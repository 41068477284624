const logotext = "Will Sands";
const meta = {
    title: "Will Sands - French Horn",
    description: "Musician, Performer, Teacher",
};

const introdata = {
    title: "Will Sands - French Horn",
    animated: {
        first: "Musician",
        second: "Performer",
        third: "Teacher",
    },
    description: "",
};

const dataabout = {
    title: "Biography",
    aboutme: 'William Sands is a rising young artist hailing from Pittsburgh, Pennsylvania. He began studying french horn at the age of 8 and quickly demonstrated an innate talent for the instrument. In high school he studied with Robert Lauver.  \nHis pursuit of musical excellence led him to the prestigious Eastman School of Music, where he studied under Peter Kurau, Maura McCune Corvington, and Jacek Muzyk. Currently, William is continuing his musical education at the Yale School of Music under the guidance of William Purvis. As a dedicated orchestral musician, William has had the privilege of performing with the Eastman Wind Ensemble, Yale Philharmonia, New Music New Haven, Symphoria, the Buffalo Philharmonic Orchestra, and the Harrisburg Symphony Orchestra.',
};
const worktimeline = [
    {
        jobtitle: "MM Candidate",
        where: "Yale School of Music",
        date: "2022-Present",
    },
    {
        jobtitle: "Substitute Horn",
        where: "Harrisburg Symphony",
        date: "2023-Present",
    },
    {
        jobtitle: "Runner Up",
        where: "West Point Band 2nd Horn Audition",
        date: "2023",
    },
    {
        jobtitle: "Finalist",
        where: "Harrisburg Symphony Asst. Principal Horn Audition",
        date: "2023",
    },
    {
        jobtitle: "Semi-Finalist",
        where: "Harrisburg Symphony 2nd Horn Audition",
        date: "2023",
    },
    {
        jobtitle: "Substitute Horn",
        where: "Symphoria",
        date: "2022-Present",
    },
    {
        jobtitle: "Substitute Horn",
        where: "Buffalo Philharmonic",
        date: "2022-2023",
    },
    {
        jobtitle: "Bachelor of Music (with Distinction)",
        where: "Eastman School of Music",
        date: "2018-2022",
    },
    {
        jobtitle: "Bachelor of Science - Computer Science (magna cum laude)",
        where: "University of Rochester",
        date: "2018-2022",
    },
];
const upcommingPerformances = [
    {
        jobtitle: "Music of Rodgers and Hammerstein - Harrisburg Symphony",
        where: "The Forum Auditorium - Harrisburg PA",
        date: "Saturday December 1st, 7:30 PM and Sunday, December 2nd, 3:00 PM",
    },
    {
        jobtitle: "New Music for Orchestra - Yale School of Music",
        where: "Woolsey Hall - YSM",
        date: "Thursday December 7th, 7:30 PM",
    },
    {
        jobtitle: "Home for the Holidays - Symphoria",
        where: "Crouse Hinds Theater - Syracuse NY",
        date: "Saturday December 16th, 1:30 PM and 7:30PM",
    },
    {
        jobtitle: 'Harry Potter and the Sorcerer\'s Stone™ in Concert - Symphoria',
        where: "Landmark Theater - Syracuse NY",
        date: "Friday January 19th 2024, 8:00 PM"
    }
];

const skills = [{
        name: "Python",
        value: 90,
    },
    {
        name: "Djano",
        value: 85,
    },
    {
        name: "Javascript",
        value: 80,
    },
    {
        name: "React",
        value: 60,
    },
    {
        name: "Jquery",
        value: 85,
    },
];

const services = [{
        title: "UI & UX Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Mobile Apps",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [
    {
        video: <iframe width="560" height="315" src="https://www.youtube.com/embed/NQAe0MERVcM?si=wlewkvn8OFtotF-U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    },
    {video: <iframe width="560" height="315" src="https://www.youtube.com/embed/pPj0tBg7V54?si=TKBhhcEVhIINDgfj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
];

const contactConfig = {
    YOUR_EMAIL: "will.sands.v@gmail.com",
    YOUR_FONE: "(412)-608-6516",
    description: "Feel free to reach out regarding lessons, engagements, performances, or any other questions you might have, or contact me directly via phone or email.",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_8cn2pil",
    YOUR_TEMPLATE_ID: "template_m2d9nad",
    YOUR_USER_ID: "-MpIMf8hbUqJLzc9E",
};

const socialprofils = {
    linkedin: "https://www.linkedin.com/in/whsands/",
    facebook: "https://www.facebook.com/wsands1701",
    instagram: "https://www.instagram.com/wsands_music/"
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
    upcommingPerformances,
};